import AWS from 'aws-sdk';

AWS.config.update({
  region: process.env.REACT_APP_AWS_REGION,
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
});

const dynamoDB = new AWS.DynamoDB.DocumentClient();
const TABLE_NAME = 'email-self';

const saveDataToDynamoDB = async (data) => {
  const params = {
    TableName: TABLE_NAME,
    Item: data,
  };

  try {
    console.log('Saving data to DynamoDB', data);
    await dynamoDB.put(params).promise();
    console.log('Data saved successfully');
  } catch (error) {
    console.error('Error saving data:', JSON.stringify(error, null, 2));
  }
};

export default saveDataToDynamoDB;
