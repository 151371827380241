import React, { useState } from 'react';
import { EMAIL_STATES } from './constants';
import { TextField, Button, Container, Typography, Box } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import saveDataToDynamoDB from './AwsService';
import SendIcon from '@mui/icons-material/Send';

const App = () => {
  const getstartDate = () => {
    let today = new Date();
    let startDate = new Date(today);
    startDate.setDate(today.getDate() + 2);
    return startDate;
  };

  // Form states
  const [to_email, setEmail] = useState('');
  const [date, setDate] = useState(getstartDate());
  const [content, setContent] = useState('');

  // Error states
  const [emailError, setEmailError] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [messageError, setMessageError] = useState(true);

  const dateErrorMessage = React.useMemo(() => {
    switch (dateError) {
      case 'minDate': {
        return 'Please select a future date';
      }

      case 'invalidDate': {
        return 'Your date is not valid';
      }

      default: {
        return '';
      }
    }
  }, [dateError]);


  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    setEmailError(!validateEmail(value));
  };

  const handleDateChange = (dateVal) => {;
    setDate(dateVal);
  };

  const handleMessageChange = (e) => {
    let message = e.target.value;
    setContent(message)
    setMessageError(message.trim() === '');
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/i;
    return re.test(String(email).toLowerCase());
  };

  const clearForm = () => {
    setEmail('');
    setDate(getstartDate()); // Assuming getstartDate function returns initial date
    setContent('');
    setEmailError(false);
    setDateError(false);
    setMessageError(true); // You can set this based on your initial state logic
  };


  const handleSubmit = async (event) => {
    event.preventDefault();

    let data = {
      to_email,
      date: date.toISOString(),
      content,
      status: EMAIL_STATES.SCHEDULED,
      user_hash: `${date.valueOf()}_${to_email}`
    };

    try {
      await saveDataToDynamoDB(data);
      alert('Data saved successfully!');
      clearForm();
    } catch (error) {
      console.error('Error occurred while saving data:', error);
      alert('Error! Please try again.');
    }
  };


  return (
    <Container>
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          Schedule Email
        </Typography>
        <form onSubmit={handleSubmit}>
          <div>
            <TextField
              label="Email"
              type="email"
              value={to_email}
              onChange={(e) => handleEmailChange(e)}
              fullWidth
              margin="normal"
              required
              error={emailError}
              helperText={emailError ? 'Please enter a valid email address' : ''}
            />
          </div>
          <div>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Date"
                value={date}
                onChange={handleDateChange}
                renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
                minDate={getstartDate()}
                disablePast
                onError={(newError) => setDateError(newError)}
                slotProps={{
                  textField: {
                    helperText: dateErrorMessage,
                  },
                }}
              />
            </LocalizationProvider>
          </div>
          <div>
            <TextField
              label="Mail Content"
              multiline
              rows={4}
              value={content}
              onChange={(e) => handleMessageChange(e)}
              fullWidth
              margin="normal"
              required
            />
          </div>
          <Button type="submit" variant="contained" color="primary" endIcon={<SendIcon />} style={{ textTransform: 'none' }} disabled={emailError || dateError || messageError}>
            Send to your Future self.
          </Button>
        </form>
      </Box>
    </Container>
  );
};

export default App;
